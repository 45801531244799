<template>
  <v-row align="center" justify="center">
    <v-col cols="3">{{ displayDays }} <br> Tage</v-col>
    <v-col cols="3">{{ displayHours }} <br> Stunden</v-col>
    <v-col cols="3">{{ displayMinutes }} <br> Minuten</v-col>
    <v-col cols="3">{{ displaySeconds }} <br> Sekunden</v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    displayDays: 0,
    displayHours: 0,
    displayMinutes: 0,
    displaySeconds: 0
  }),
  computed: {
    _seconds: () => 1000,
    _minutes() {
      return this._seconds * 60;
    },
    _hours() {
      return this._minutes * 60;
    },
    _days() {
      return this._hours * 24;
    }
  },
  mounted() {
    this.showRemaining();
  },
  props: {
    date: Date
  },
  methods: {
    formatNum: num => (num < 10 ? "0" + num : num),
    showRemaining() {
      setInterval(() => {
        const now = new Date();
        const end = this.date;
        const distance = Math.abs(end.getTime() - now.getTime());

        const days = Math.floor(distance / this._days);
        const hours = Math.floor((distance % this._days) / this._hours);
        const minutes = Math.floor((distance % this._hours) / this._minutes);
        const seconds = Math.floor((distance % this._minutes) / this._seconds);
        this.displaySeconds = this.formatNum(seconds);
        this.displayMinutes = this.formatNum(minutes);
        this.displayHours = this.formatNum(hours);
        this.displayDays = days;
      }, 1000);
    }
  }
};
</script>

<style>

</style>